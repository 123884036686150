import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {PostRequest} from "../../model/requests";
import {IdResponse, Page, PostCommentResponse, PostResponse} from "../../model/responses";
import {FeedType} from "../../model/feed-type";

@Injectable()
export class PostService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getPost(postId: string): Observable<PostResponse> {
        return this.http.get<PostResponse>(`${environment.apiUrl}/posts/${postId}`);
    }

    createPost(request: PostRequest, attachments: Blob[]): Observable<IdResponse> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        for (let i = 0; i < attachments.length; i++) {
            formData.append(`attachment-${i}`, attachments[i]);
        }
        return this.http.post<IdResponse>(`${environment.apiUrl}/posts`, formData);
    }

    deletePost(postId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/posts/${postId}`);
    }
}
