import {Component} from "@angular/core";
import {UserGameService} from "../service/api/user-game.service";
import {PostRequest} from "../model/requests";
import {PostService} from "../service/api/post.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {WritePostFormComponent} from "./write-post-form.component";
import {GameSearchFormComponent} from "../game/game-search-form.component";
import {CommonModule} from "@angular/common";
import {CurrentViewDataService} from "../service/current-view-data.service";
import {BoardGameResponse} from "../model/responses";
import {UserGameOwnership} from "../model/user-game-ownership";
import {SharedDialogsService} from "../service/shared-dialogs.service";

@Component({
    selector: 'cb-new-post-dialog',
    template: `
        <h1 mat-dialog-title i18n="@@postVerb">Post</h1>
        <mat-dialog-content>
            <div class="write-post-form" *ngIf="!saving">
                <cb-game-search-form [selectedGame]="selectedGame" [error]="gameError" required="false"
                                     (selectedGameChange)="selectGame($event?.slug)"></cb-game-search-form>
                <cb-write-post-form postContentRequired [(postContent)]="postContent"
                                    [(files)]="files"></cb-write-post-form>
            </div>
            <div class="saving column center align-center" *ngIf="saving">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between" style="margin-top: 16px">
            <button mat-button cdkFocusInitial (click)="cancel()" i18n>Cancel</button>
            <button mat-flat-button color="primary" (click)="save()" [disabled]="saving || !canPublish()" *ngIf="true"
                    i18n>Publish</button>
        </div>
    `,
    styles: [`
        .write-post-form {
        }

        .write-post-attachment-buttons {
            margin-top: 10px;
            margin-right: 10px;
        }

        .saving {
            margin-top: 32px;
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [MatDialogModule, CommonModule, GameSearchFormComponent, WritePostFormComponent, MatProgressSpinnerModule, MatButtonModule]
})
export class NewPostDialogComponent {

    selectedGame?: BoardGameResponse
    targetOwnership?: UserGameOwnership

    gameError?: string

    postContent = ''
    files: Blob[] = []

    saving = false

    constructor(
        private userGameService: UserGameService,
        private postService: PostService,
        private snackBar: MatSnackBar,
        private currentViewDataService: CurrentViewDataService,
        private dialogRef: MatDialogRef<NewPostDialogComponent>,
        private sharedDialogsService: SharedDialogsService,
    ) {
        this.selectGame(this.currentViewDataService.selectedGame?.slug)
    }

    selectGame(gameSlug?: string) {
        if (!gameSlug) {
            this.selectedGame = undefined;
            return
        }

        this.userGameService.getGame(gameSlug).subscribe(game => {
            this.selectedGame = game;
        })
    }

    canPublish(): boolean {
        return !this.gameError && (this.postContent.length > 0 || this.files.length > 0)
    }

    cancel() {
        if (this.postContent.length > 0 || this.files.length > 0) {
            this.sharedDialogsService.openCancelDialog().subscribe(result => {
                if (result) {
                    this.dialogRef.close()
                }
            })
        } else {
            this.dialogRef.close()
        }
    }

    save() {
        if (this.gameError) {
            return
        }

        if (this.postContent.length == 0 && this.files.length == 0) {
            return
        }

        const postRequest: PostRequest = {
            gameId: this.selectedGame?.id,
            text: this.postContent.length > 0 ? this.postContent : undefined
        }
        this.saving = true
        this.postService.createPost(postRequest, this.files).subscribe({
            next: response => {
                this.saving = false
                this.dialogRef.close(response)
            },
            error: error => {
                this.saving = false
                this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
            }
        })
    }
}

