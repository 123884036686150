import {Component, Inject} from "@angular/core";
import {UserGameService} from "../service/api/user-game.service";
import {UserGameAddRequest} from "../model/requests";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {getUserGameOwnershipData, UserGameOwnership, userGameOwnershipData,} from "../model/user-game-ownership";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDividerModule} from "@angular/material/divider";
import {GameSearchFormComponent} from "./game-search-form.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {CurrentViewDataService} from "../service/current-view-data.service";
import {BoardGameResponse} from "../model/responses";
import {dateToString} from "../model/period";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {SharedDialogsService} from "../service/shared-dialogs.service";
import {WritePostFormComponent} from "../post/write-post-form.component";

@Component({
    selector: 'cb-game-ownership-dialog',
    template: `
        <h1 mat-dialog-title>{{ getUserGameOwnershipTexts(targetOwnership).verb }}</h1>
        <mat-dialog-content>
            <form class="add-game-form column" *ngIf="!saving">
                <mat-form-field class="text-field" appearance="fill" *ngIf="!data?.targetOwnership" style="margin-top: 8px">
                    <mat-label i18n>Add to</mat-label>
                    <mat-select [(value)]="targetOwnership">
                        <mat-option *ngFor="let ownership of userGameOwnerships"
                                    [value]="ownership.id">{{ ownership.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <cb-game-search-form cdkFocusInitial [selectedGame]="selectedGame" [error]="gameError"
                                     (selectedGameChange)="selectGame($event?.slug)"></cb-game-search-form>

                <mat-form-field appearance="fill">
                    <mat-label i18n>{{ getUserGameOwnershipTexts(targetOwnership).name }} Since</mat-label>
                    <input matInput [matDatepicker]="picker" [value]="ownershipSince"
                           (dateChange)="selectOwnershipSince($event)"
                           readonly
                           (click)="picker.open()"
                           (focus)="picker.open()"
                           #dateInput>
                    <mat-datepicker-toggle matSuffix *ngIf="!ownershipSince" [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-icon-button matSuffix *ngIf="ownershipSince"
                            (click)="$event.stopPropagation(); ownershipSince = undefined; dateInput.blur()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label i18n>Price (optional)</mat-label>
                    <input matInput type="number" min="0" step="0.01" placeholder="16.50" [(ngModel)]="price"
                           name="price">
                </mat-form-field>

                <mat-form-field class="text-field" appearance="fill">
                    <mat-label i18n>Personal Notes (optional)</mat-label>
                    <textarea matInput cdkTextareaAutosize [(ngModel)]="notes" i18n-placeholder placeholder="Personal Notes"
                              rows="3" name="personal-notes"></textarea>
                </mat-form-field>
                <cb-write-post-form i18n-title title="Write post (optional)"
                                    [postContentRequired]="false"
                                    [(postContent)]="postContent"
                                    [(files)]="files"
                ></cb-write-post-form>
            </form>
            <div class="saving column center align-center" *ngIf="saving">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between" style="margin-top: 16px">
            <button mat-button cdkFocusInitial (click)="cancel()" i18n>Cancel</button>
            <button mat-flat-button color="primary" (click)="save()"
                    [disabled]="!selectedGame || gameError != undefined"
                    *ngIf="true" i18n>Save</button>
        </div>
    `,
    styles: [`
        .section-separation {
            margin-bottom: 16px;
        }

        .add-game-form {

            .mat-form-field {
                width: 100%;
            }
        }

        .form-heading {
            margin-top: 15px;
            margin-bottom: 10px;
            font-size: 1.1rem;
            font-weight: 700;
        }

        .optional-heading {
            margin-left: 8px;
            font-size: 1rem;
            font-weight: 400;
            color: var(--secondary-text-color);
        }

        .saving {
            margin-top: 32px;
        }

        .write-post-attachment-buttons {
            margin-top: 10px;
            margin-right: 10px;
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [MatDialogModule, CommonModule, ReactiveFormsModule, FormsModule, GameSearchFormComponent, MatDividerModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatOption, MatSelect, WritePostFormComponent]
})
export class GameOwnershipDialogComponent {

    targetOwnership: UserGameOwnership = UserGameOwnership.OWNED
    selectedGame?: BoardGameResponse

    gameError?: string

    postContent = ''
    files: Blob[] = []

    saving = false

    ownershipSince?: Date = new Date()
    notes?: string
    price?: number

    constructor(
        private userGameService: UserGameService,
        private snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<GameOwnershipDialogComponent>,
        private sharedDialogsService: SharedDialogsService,
        private currentViewDataService: CurrentViewDataService,
        @Inject(MAT_DIALOG_DATA) public data?: { targetOwnership?: UserGameOwnership }
    ) {
        this.targetOwnership = data?.targetOwnership || UserGameOwnership.OWNED
        this.selectGame(this.currentViewDataService.selectedGame?.slug)
    }

    selectGame(gameSlug?: string) {
        if (!gameSlug) {
            this.selectedGame = undefined;
            return
        }

        this.userGameService.getGame(gameSlug).subscribe(game => {
            this.selectedGame = game;
            if (game.userAnnotations?.ownership == this.targetOwnership) {
                this.gameError = $localize`You already own this game`;
            } else {
                this.gameError = undefined;
            }
            if (game && game.userAnnotations) {
                this.notes = game.userAnnotations.notes
                this.price = game.userAnnotations.price
            }
        })
    }

    selectOwnershipSince(event: any) {
        this.ownershipSince = new Date(Date.UTC(event.value.getFullYear(), event.value.getMonth(), event.value.getDate()));
    }

    cancel() {
        if (this.postContent.length > 0 || this.files.length > 0) {
            this.sharedDialogsService.openCancelDialog().subscribe(result => {
                if (result) {
                    this.dialogRef.close()
                }
            })
        } else {
            this.dialogRef.close()
        }
    }

    save() {
        if (!this.selectedGame) {
            this.gameError = $localize`Please select a game`
            return
        }

        if (this.gameError) {
            return
        }

        const userGameUpdateRequest: UserGameAddRequest = {
            ownership: this.targetOwnership,
            ownershipSince: this.ownershipSince ? dateToString(this.ownershipSince) : undefined,
            notes: this.notes,
            price: this.price,
            postText: this.postContent.length > 0 ? this.postContent : undefined,
        }
        this.saving = true
        this.userGameService.addGame(this.selectedGame.id, userGameUpdateRequest, this.files).subscribe({
            next: () => {
                this.saving = false
                this.snackBar.open($localize`Game ownership updated`, 'OK', {duration: 5000})
                this.dialogRef.close(userGameUpdateRequest)
            },
            error: error => {
                this.saving = false
                this.snackBar.open(error.error.message || $localize`There was an error fulfilling your request. Please try again later`, 'OK', {duration: 5000})
            }
        });
    }

    protected readonly getUserGameOwnershipTexts = getUserGameOwnershipData;
    protected readonly userGameOwnerships = userGameOwnershipData
        .filter(ownership => ownership.id != undefined);
}
